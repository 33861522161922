<template>
  <div>
    <!-- Filters -->
    <cobranca-list-filters
      :codigo-credor-filter.sync="codigoCredorFilter"
      :situacao-filter.sync="situacaoFilter"
      :situacao-options="situacaoOptions"
      :venc-inicial-filter.sync="vencInicialFilter"
      :venc-final-filter.sync="vencFinalFilter"
      :valor-minimo-filter.sync="valorMinimoFilter"
      :valor-maximo-filter.sync="valorMaximoFilter"
      :tem-telefone-filter.sync="temTelefoneFilter"
      :tem-email-filter.sync="temEmailFilter"
    />

    <b-card
      class=""
    >
      <div class="demo-inline-spacing">
        <b-button
          variant="success"          
          v-b-modal.modal-cobranca
        >
          Enviar cobrança para títulos selecionados
          <feather-icon
            icon="SendIcon"
            class="ml-50"
          />
        </b-button>
        <b-button
          variant="outline-danger"  
          @click="cobradoFilter = 0"    
          v-if="cobradoFilter"    
        >
          Visualizar títulos ainda não cobrados
          <feather-icon
            icon="XCircleIcon"
            class="ml-50"
          />
        </b-button>
        <b-button
          variant="outline-warning"      
          @click="cobradoFilter = 1"
          v-else
        >
          Visualizar títulos já cobrados hoje
           <feather-icon
            icon="CheckCircleIcon"
            class="ml-50"
          />
        </b-button>
        <b-button
          variant="outline-primary"      
          @click="retornarTitulos()"
        >
          Retornar títulos que foram cobrados
           <feather-icon
            icon="SkipBackIcon"
            class="ml-50"
          />
        </b-button>
      </div>

      <!-- modal login-->
      <b-modal
        id="modal-cobranca"
        cancel-variant="outline-secondary"
        ok-variant="success"
        ok-title="Confirmar"
        cancel-title="Cancelar"
        centered
        title="Realizar o envio das cobranças"
        @ok="iniciarCobranca"
      >
        <b-form>
          <b-form-group>
            <label>Selecione a mensagem</label> 
            <v-select
              v-model="message"
              :options="messagesOptions"
              :clearable="false"
              label="titulo"
              placeholder="Pesquisar..."
              :reduce="message => message.id"
            />
          </b-form-group>

          <b-form-group>
            <label>Selecione os canais</label> 
            <v-select
              v-model="channels"
              :options="channelsOptions"
              multiple
              :clearable="false"
              label="nome"
              placeholder="Pesquisar..."
              :reduce="channel => channel.id"
            />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Barra de ações -->
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >           
            <b-button
              :variant="selectedAll ? 'success' : 'outline-success'"
              class="mr-1"
              @click="selectAll"
            >
              <div v-if="selectedAll">
                <span class="text-nowrap">Desfazer seleção</span>
                <feather-icon
                  icon="XIcon"
                  class="ml-50"
                />
              </div>
              <div v-else>
                <span class="text-nowrap">Selecionar todos</span>
                <feather-icon
                  icon="CheckIcon"
                  class="ml-50"
                />
              </div>
            </b-button>

            <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50 select-size-sm"
              />
            <label>registros</label>    
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Procurar..."
              />              
            </div>    
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCobrancaListTable"
        class="position-relative"
        responsive
        striped
        :items="fetchCobrancas"       
        :fields="tableColumns"  
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <!-- Column: User -->
        <template #cell(devedor)="data">
          <b-link           
            class="font-weight-bold d-block text-nowrap" 
          >
            {{ data.item.devedor }}
          </b-link>
        </template>

        <template #cell(#)="data">
            <b-form-checkbox
              v-model="selected"
              :value="selectedAll == true ? 'all' : data.item.id"
              class="custom-control-primary"
            >              
            </b-form-checkbox>
        </template>

        <!-- Column: Credor -->
        <template #cell(credor)="data">
          <span class="font-weight-bolder">{{ data.item.credor }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(situacao)="data">
          <b-badge
            pill
            :variant="`light-${resolveCobrancaStatusVariant(data.item.situacao)}`"
            class="text-capitalize"
          >
            {{ data.item.situacao }}
          </b-badge>
        </template>
      </b-table>

      <!-- METADATA -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo de {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.total }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BPagination, BFormCheckbox,BModal,BFormFile, BForm, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { watch, ref, onUnmounted, onBeforeMount } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import CobrancaListFilters from './CobrancaListFilters.vue'
import useCobrancaList from './useCobrancaList'
import cobrancaStoreModule from '../cobrancaStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default {
  components: {
    CobrancaListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BFormCheckbox,
    BModal,
    BForm,
    BFormGroup,

    vSelect,
  },
  setup() {
    const COBRANCA_APP_STORE_MODULE_NAME = 'app-cobranca'

    // Register module
    if (!store.hasModule(COBRANCA_APP_STORE_MODULE_NAME)) store.registerModule(COBRANCA_APP_STORE_MODULE_NAME, cobrancaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COBRANCA_APP_STORE_MODULE_NAME)) store.unregisterModule(COBRANCA_APP_STORE_MODULE_NAME)
    })

    const message = ref(null)
    const messagesOptions = ref([])
    const channels = ref([])
    const channelsOptions = ref([])
    
    const situacaoOptions = [
      { label: 'Negativado', value: 'NEGATIVADO' },
      { label: 'Prescrito', value: 'PRESCRITO' },
      { label: 'Cadastrado', value: 'CADASTRADO' }
    ]

    onBeforeMount(() => {
      axios
      .get('cobranca/mensagens')
      .then(response => {
        const { data } = response.data  
        messagesOptions.value = data
      })
      .catch(error => reject(error))  

      axios
      .get('cobranca/canais')
      .then(response => {
        const { data } = response.data  
        channelsOptions.value = data
      })
      .catch(error => reject(error))  
    })

    const selected  = ref([])
    const selectedAll = ref(false)

    
    function selectAll() {
      if(selectedAll.value == true){
        selectedAll.value = false
        selected.value = []
      }else{
        selectedAll.value = true
        selected.value = ['all']  
      }    
    }

    function retornarTitulos() {
      axios.post('cobrancas/retornar')
      .then((response) => {
        this.$refs.refCobrancaListTable.refresh();
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Gestão de títulos',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Os títulos irão retornar a cobrança!`,
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Gestão de títulos',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: `Falha ao retornar títulos ):`,
          },
        })
      });
    }

    // Verifica quando eu clico em um check e a opção selectAll ta ativada
    watch(selected, (currentValue, oldValue) => {
      if(selectedAll.value == true && oldValue.length == 1 && currentValue.length == 0){
        selectedAll.value = false
      }
    });

    function iniciarCobranca() {
      if(selected.value.length > 0){
        axios.post('cobrancas/iniciar',{
          selected: selected.value,
          selectedAll: selectedAll.value,
          busca: searchQuery.value,
          cobrado: cobradoFilter.value,
          message: message.value,
          channels: channels.value
        })
        .then((response) => {
          this.$refs.refCobrancaListTable.refresh();
          selected.value = []
          selectedAll.value = false
          message.value = null
          channels.value = []
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Envio de cobrança',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: `Títulos enviados para cobrança com sucesso!`,
            },
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Envio de cobrança',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: `Não foi possível enviar seus títulos para cobrança ):`,
            },
          })
        });
      }else{
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Envio de cobrança',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: `Selecione ao menos um título.`,
          },
        })
      }
    }

    const {
      fetchCobrancas,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      refCobrancaListTable,
      refetchData,
      cobradoFilter,

      // UI
      resolveCobrancaStatusVariant,

      //filters
      codigoCredorFilter,
      situacaoFilter,
      vencInicialFilter,
      vencFinalFilter,
      valorMinimoFilter,
      valorMaximoFilter,
      temTelefoneFilter,
      temEmailFilter

    } = useCobrancaList()

    return {

      fetchCobrancas,
      tableColumns,
      perPage,
      currentPage,    
      dataMeta,
      perPageOptions,
      searchQuery,
      refCobrancaListTable,
      refetchData,
      cobradoFilter,
      selected,
      selectedAll,
      selectAll,
      message,
      messagesOptions,
      channels,
      channelsOptions,
      retornarTitulos,

      // Filter
      avatarText,
      codigoCredorFilter,
      situacaoOptions,
      situacaoFilter,
      vencInicialFilter,
      vencFinalFilter,
      valorMinimoFilter,
      valorMaximoFilter,
      temTelefoneFilter,
      temEmailFilter,

      // UI
      resolveCobrancaStatusVariant,
      iniciarCobranca

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
