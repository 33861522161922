<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Código Credor</label>
          <b-form-input
            id="codigo_credor"
            placeholder="102030"              
            @input="(val) => $emit('update:codigoCredorFilter', val)"
          />
        </b-col>     
        <b-col
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Situação</label>
          <v-select
            :value="situacaoFilter"
            :options="situacaoOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:situacaoFilter', val)"
          />
        </b-col>       
        <b-col
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Venc Inicial</label>
          <b-form-input
            id="venc_inicial"
            type="date"     
              
            @input="(val) => $emit('update:vencInicialFilter', val)"
          />
        </b-col>       
        <b-col
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Venc Final</label>
          <b-form-input
            id="venc_final"
            type="date"     
              
            @input="(val) => $emit('update:vencFinalFilter', val)"
          />
        </b-col>    
        <b-col
          md="1"
          class="mb-md-0 mb-2"
        >
          <label>Valor mínimo</label>
          <b-form-input
            id="valor_minimo"
            placeholder="0,00"              
            @input="(val) => $emit('update:valorMinimoFilter', val)"
          />
        </b-col>       
        <b-col
          md="1"
          class="mb-md-0 mb-2"
        >
          <label>Valor máximo</label>
          <b-form-input
            id="valor_maximo"
            placeholder="0,00"              
            @input="(val) => $emit('update:valorMaximoFilter', val)"
          />
        </b-col>        
        
      </b-row>
      <b-row class="mt-1">
        <b-col
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Tem telefone?</label>
          <v-select
            :value="temTelefoneFilter"
            :options="contatoOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:temTelefoneFilter', val)"
          />
        </b-col>     
        <b-col
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Tem email?</label>
          <v-select
            :value="temEmailFilter"
            :options="contatoOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:temEmailFilter', val)"
          />
        </b-col>     
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    vSelect,
  },
  props: {
    codigoCredorFilter: {
      default: null,
    },
    situacaoFilter: {
      type: [String, null],
      default: null,
    },
    situacaoOptions: {
      type: Array,
      required: true,
    },
    vencInicialFilter: {
      default: null,
    },
    vencFinalFilter: {
      default: null,
    },
    valorMinimoFilter: {
      default: null,
    },
    valorMaximoFilter: {
      default: null,
    }
  },
  setup() {
    const contatoOptions = [
      { label: 'Todos', value: 'TODOS' },
      { label: 'Sim', value: 'SIM' },
      { label: 'Não', value: 'NAO' }
    ]

    return {
      contatoOptions
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
