import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCobrancaList() {
  // Use toast
  const toast = useToast()

  const refCobrancaListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    '#',
    { key: 'credor', label: "Credor"},
    { key: 'devedor'},
    { key: 'numero_titulo'},
    { key: 'vencimento'},
    { key: 'valor'},
    { key: 'situacao' },
    { key: 'telefones'},
    { key: 'emails'},
    // { key: 'actions', label: "Ações" },
  ]
  const perPage = ref(15)
  const currentPage = ref(1)
  const perPageOptions = [10, 15, 25, 50, 100]
  const searchQuery = ref('')
  const cobradoFilter = ref(0)
  const codigoCredorFilter = ref(null)
  const situacaoFilter = ref(null)
  const vencInicialFilter = ref(null)
  const vencFinalFilter = ref(null)
  const valorMinimoFilter = ref(null)
  const valorMaximoFilter = ref(null)
  const temTelefoneFilter = ref(null)
  const temEmailFilter = ref(null)

  const dataMeta = ref({})

  const refetchData = () => {
    refCobrancaListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, cobradoFilter, codigoCredorFilter, situacaoFilter, vencInicialFilter, vencFinalFilter, valorMinimoFilter, valorMaximoFilter, 
    temTelefoneFilter, temEmailFilter], () => {
    refetchData()
  })

  const fetchCobrancas = (ctx, callback) => {
    store
      .dispatch('app-cobranca/fetchCobrancas', {
        busca: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        cobrado: cobradoFilter.value,
        codigo_credor: codigoCredorFilter.value,
        situacao: situacaoFilter.value,
        venc_inicial: vencInicialFilter.value,
        venc_final: vencFinalFilter.value,
        valor_minimo: valorMinimoFilter.value,
        valor_maximo: valorMaximoFilter.value,
        has_telefone: temTelefoneFilter.value,
        has_email: temEmailFilter.value,
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching titulos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCobrancaStatusVariant = status => {
    if (status === 'NEGATIVADO') return 'primary'
    if (status === 'BAIXADO') return 'success'
    if (status === 'PRESCRITO') return 'danger'
    if (status === 'CADASTRADO') return 'info'
    return 'primary'
  }

  return {
    fetchCobrancas,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refCobrancaListTable,
    cobradoFilter,

    resolveCobrancaStatusVariant,
    refetchData,

    // Extra Filters
    codigoCredorFilter,
    situacaoFilter,
    vencInicialFilter,
    vencFinalFilter,
    valorMinimoFilter,
    valorMaximoFilter,
    temTelefoneFilter,
    temEmailFilter
  }
}
